/**
 * Represents an Event, adding in some useful information, ready to be sent to the Zuko API
 * @module ZukoEvent
 */
define(["./field"], function(ZukoField) {
  "use strict";

  /**
   * @param {Object|event} event Can be a native JS event or a simple hash
   * @param {string} visitorId The ID of the visitor
   * @param {string} formSlug Slug of the element we're tracking
   * @param {string} domain Domain that this event occurred on
   * @param {Object} attributes The attributes that have been assigned to a Zuko instance
   * @param {string} [replayId] A session replay ID can be provided
   * @typedef {Object} ZukoEvent
   * @alias module:ZukoEvent
   * @class
   */
  var ZukoEvent = function(event, visitorId, formSlug, domain, attributes, replayId) {
    if (visitorId && typeof visitorId === "string") {
      this.visitorId = visitorId;
    } else {
      throw new Error("visitorId is required");
    }

    if (formSlug && typeof formSlug === "string") {
      this.formSlug = formSlug;
    } else {
      throw new Error("formSlug is required");
    }

    if (domain && typeof domain === "string") {
      this.domain = domain;
    } else {
      throw new Error("domain is required");
    }

    if (attributes && attributes instanceof Object && !Array.isArray(attributes)) {
      this.attributes = attributes;
    } else {
      throw new Error("attributes is required");
    }

    if (event && typeof event === "object") {
      this.timeStamp = ZukoEvent.convertTimeStampToEpoch(event.timeStamp || new Date().getTime());
      if (event.type && typeof event.type === "string") {
        this.type = event.type;
      } else {
        throw new Error("an event type is required");
      }

      if (event.target) {
        this.field = new ZukoField(event.target);
      }

    } else {
      throw new Error("an event is required");
    }

    if (replayId && typeof replayId === "string") {
      this.replayId = replayId;
    }
  };

  /**
   * Sanitises event timestamps to be milliseconds since epoch and returns the sanitized value.
   * Some browsers deem the epoch to be the page load, so event timestamps are relative to that, and some use the
   * standard Unix epoch (Jan 1 1970)
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Event/timeStamp
   * @see https://googlechrome.github.io/samples/event-timestamp/
   * @param {number} eventTimeStamp The timestamp of an event
   * @returns {number} The amount of milliseconds since epoch
   */
  ZukoEvent.convertTimeStampToEpoch = function(eventTimeStamp) {
    if (window.performance !== "undefined") {
      if (window.performance.timing !== "undefined") {
        if(eventTimeStamp < performance.timing.navigationStart) {
          return eventTimeStamp + performance.timing.navigationStart;
        }
      }
    }
    // We assume it's safe to just "resort" to returning the original timestamp, if performance isn't available
    return eventTimeStamp;
  };

  return ZukoEvent;
});
